.table-wrapper {
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 3px 9px 0 rgba(38, 55, 72, 0.1);
  background-color: white;
}

.table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;

  th {
    padding-top: 14px;
    padding-bottom: 14px;
    padding-right: 10px;

    font-size: 15px;
    font-style: normal;
    font-weight: normal;
    text-align: left;
    text-transform: none;
    color: #3d4461;

    border-bottom: solid 1px #e5e5e5;

    &:first-child {
      padding-left: 30px;
    }

    .input {
      width: 100%;
      font-size: 13px;
      padding: 10px 14px;
    }
  }

  td {
    padding-top: 14px;
    padding-bottom: 14px;
    padding-right: 10px;

    font-size: 14px;
    color: #84889d;

    border-bottom: solid 1px #e5e5e5;

    vertical-align: middle;

    &:first-child {
      padding-left: 30px;
    }
  }

  tboby {
    tr {
      &:hover {
        box-shadow: 0 12px 20px 0 rgba(38, 55, 72, 0.16);
      }
    }
  }
}
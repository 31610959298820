.ReactModalPortal {
  position: relative;
  z-index: 1000;
}

.modal-content {
  padding: 30px 50px;
  border-radius: 5px;
  box-shadow: 0 3px 9px 0 rgba(38, 55, 72, 0.1);
  background-color: #ffffff;

  input {
    width: 100%;
  }
}

.modal-body {
  // background-color: red;
  margin-bottom: 30px;
}

.modal-footer {
  .button {
    margin-right: 10px;
  }
}
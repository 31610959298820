.nonstructured {
  &__error {
    margin-bottom: 24px;
  }
}

.edit-document-table {
  margin: 36px 160px;

  .add-row-btn {
    display: block;
    width: calc(100% + 74px);
    margin-left: -48px;
    padding: 19px 50px;
    height: auto;

    font-size: 15px;
    color: #1ca3ff;
    border: solid 0.5px #80cbff;
    background-color: #eff9ff;

    &:hover {
      color: white;
    }
  }

  &-row {
    margin-left: -48px;
    margin-right: -26px;
    margin-bottom: 14px;

    padding-left: 48px;
    padding-right: 26px;
    padding-bottom: 20px;

    border-radius: 5px;
    border: solid 1px #f5f5f5;
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.07);
    background-color: #ffffff;

    &-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 20px;

      .input,
      .react-datepicker__input-container {
        width: 100%;
      }
    }
  }
}

.edit-document {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  margin: 36px 160px;

  h3 {
    margin-bottom: 0px;
  }

  .fullcell {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .input,
  .react-datepicker__input-container {
    width: 100%;
  }

  .radio-list {
    align-items: center;
    height: 36px;

    .radio-item {
      margin-right: 20px;
    }
  }
}

@media(max-width: 1024px) {
  .edit-document {
    margin: 0;
    grid-template-columns: 1fr;

    .fullcell {
      grid-column-start: auto;
      grid-column-end: auto;
    }

    .radio-list {
      height: auto;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: flex-start;
      li {
            align-self: self-start;
            margin: 10px 0;
        // display: block;
      }
    }
  }

  .edit-document-table {
    margin: 0;

    &-row {
      box-shadow: none;
      margin-bottom: 0;

      &-grid {
        grid-template-columns: 1fr;
      }
    }
  }

  .newtemplate__metaData {
    display: block !important;
  }
}
.radio {
  color: #3d4461;

  &-list {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    list-style: none;

    color: #282d32;
    font-size: 16px;
  }

  &-label {
    margin-right: 20px;
  }

  &-item {
    padding-left: 26px;
    background: url(../assets/svg/icon-unselected.svg);
    background-repeat: no-repeat;
    background-position-y: 2px;
    height: 15px;
    display: flex;
    align-items: center;
    transition: .3s ease;
    height: 19px;
    font-size: 16px;
    font-weight: 300;
    line-height: normal;
    letter-spacing: normal;
    color: #282d32;
    cursor: pointer;

    &:checked,
    &_active {
      background: url(../assets/svg/icon-selected.svg);
      background-position-y: 2px;
      background-repeat: no-repeat;
    }
  }
}
.new-document {
  .base__title_img img {
    width: 280px;
  }
  .base__header {
    flex-direction: column;
    margin-bottom: -30px;

    h1 {
      width: 100%;
    }
  }

  .text-title {
    line-height: 1.5;
    i {
      color: #1ca3ff;
      font-style: normal;
      border-bottom: 1px solid #1ca3ff;

      cursor: pointer;
    }
  }

  .new-document__docs {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    column-gap: 30px;
    row-gap: 30px;

    list-style: none;

    margin: 0;
    margin-top: 50px;
    padding: 0;

    &-item {
      list-style-type: none;
      overflow: hidden;

      border-bottom: 1px solid #e8e9ee;
      border-radius: 5px;
      background-color: #ffffff;
      color: #3d4461;

      cursor: pointer;

      &:hover {
        box-shadow: 0 63px 50px 0 rgba(38, 55, 72, 0.15);
      }
    }

    &-list {
      background-image: url(../../assets/img/template.png);
      background-size: contain;
      padding-bottom: 111%;
    }

    &-text {
      margin: 0 2px;
      margin-top: -5px;
      padding: 20px;

      box-shadow: 0 3px 9px 0 rgba(38, 55, 72, 0.1);
      background-color: white;

      span {
        display: block;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.react-datepicker-popper {
  z-index: 2;
}

@media (max-width: 1024px) {
  .new-document {
    .new-document__docs {
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

      &-item {
        box-shadow: 0 6px 17px 0 rgba(38, 55, 72, 0.16);
      }
    }
  }
}
// .searchString {
//     max-width: 30%;
//     margin-bottom: 0;
// }

// .new-document {
//   flex: 1;
//   display: flex;
//   flex-direction: column;

//   &__label {
//     margin: 0;
//   }

//   &__docs {
//     display: flex;
//     flex-wrap: wrap;
//     list-style: none;
//     max-width: 675px;
//     margin: 0 auto;
//     padding: 0;
//     width: 100%;

//     &-item {
//       width: 150px;
//       margin-right: 112px;
//       margin-top: 40px;
//       cursor: pointer;

//       &:nth-child(3n) {
//         margin-right: 0;
//       }

//       &_active {
//         .new-document__docs {
//           &-list {
//             box-shadow: 0 2px 12px 0 rgba(82, 161, 255, 0.2);
//             border: solid 2px #52a1ff;
//           }

//           &-text {
//             color: #52a1ff;
//           }
//         }
//       }
//     }

//     &-list {
//       width: 150px;
//       height: 200px;
//       box-shadow: 0 2px 12px 0 rgba(92, 105, 121, 0.2);
//       background-color: #ffffff;
//       border: solid 2px #fbfcff;
//       transition: .3s ease;
//     }

//     &-text {
//       font-size: 14px;
//       font-weight: 500;
//       line-height: normal;
//       letter-spacing: normal;
//       text-align: center;
//       color: #5c6979;
//       margin-top: 20px;
//       transition: .3s ease;
//     }
//   }

//   &__button {
//     margin-left: auto;
//     margin-top: 60px;
//   }
// }

// @media (max-width: 960px) {
//   .new-document {
//     &__docs {
//       margin: 0 auto;
//       max-width: 414px;

//       &-item {
//         &:nth-child(3n) {
//           margin-right: 112px;
//         }

//         &:nth-child(2n) {
//           margin-right: 0;
//         }
//       }
//     }
//   }
// }

// @media (max-width: 480px) {
//   .new-document {
//     &__docs {
//       justify-content: center;
//       &-item {
//         display: flex;
//         align-items: center;
//         flex-direction: column;
//         margin-right: 0;
//       }

//       &-item:nth-child(3n) {
//         margin-right: 0;
//       }

//       &-list {
//         width: 75px;
//         height: 100px;
//       }
//     }
//   }
// }
// @media (max-width: 384px) {
//   .new-document {
//     &__label {
//       font-size: 12px;
//     }
//   }
// }

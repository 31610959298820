.edituser {
  &__error {
    margin-bottom: 24px;
  }
}

.edit-user {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  margin: 60px 160px;

  h3 {
    margin-top: 0;
  }

  .fullcell {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .input {
    width: 100%;
  }

  .radio-list {
    li {
      margin-right: 30px;
    }
  }
}

@media (max-width: 1024px) {
  .edit-user {
    margin: 0;
    grid-template-columns: 1fr;

    .fullcell {
      grid-column-start: auto;
      grid-column-end: auto;
    }

    .radio-list {
      display: block;

      li {
        margin: 20px 0;
      }
    }
  }
}

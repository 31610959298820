.signin {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  z-index: 10;
  height: 100vh;

  min-height: 600px;

  background-image: linear-gradient(92deg, #041865, #50a0ff);
  background-image: url(../../assets/svg/background2.svg), linear-gradient(92deg, #041865, #50a0ff), ;
  background-size: 130%;
  background-position: center center;

  &__form {
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
    border-radius: 2px;

    .input {
      width: 100%;
    }
  }
  
  &__header {
    width: 100%;
    height: 210px;
    margin-bottom: 70px;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    position: relative;

    h3 {
      color: white;
    }
  }

  &__title {
    font-size: 30px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.2px;
    margin: 0;
    color: #8bebff;
  }

  &__second-title {
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.1px;
    margin: 0;
  }

  

  &__body {
    max-width: 480px;
    width: 100%;
    padding: 25px 25px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 0 6px 10px 0 rgba(38, 55, 72, 0.16);
    background-color: white;

    p {
      font-size: 25px;
      margin-bottom: 45px;
      margin-top: 0;
      font-weight: 500;
    }
  }

  &__error {
    margin-bottom: 24px;
  }
  
  &__footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;

    &-hint {
      font-size: 16px; 
      font-weight: 500;
      color: #5c6979;
    }

    &-link {
      font-weight: 500;
      text-decoration: none;
      color: #1ca3ff;
      border-bottom: 1px solid #1ca3ff;
    }

    &-button {
      width: 104px;
    }
  }
}

@media(max-width: 1024px) {
  .signin {
    overflow: hidden;
    background-size: 450%;
    background-position: center center;
    
    flex-direction: column;
    justify-content: center;

    &__header {
      padding: 0 50px;
      margin-bottom: 30px;

      .navbar-brand {
        width: 100%;
        height: 50px;
        margin-top: auto;
        margin-left: 0;
        margin-bottom: 10px;
      }

      h2 {
        font-size: 17px;
      }
    }

    &__form {
      padding: 0px 20px;

      .signin__body {
        padding: 26px 32px;

        p {
          font-size: 17px;
        }
      }
      
    }
  }
}

@media(max-width: 400px) {
.signin {
  &__header {
    padding: 0 10px;
  }
  &__form {
    .signin__body {
      padding: 10px;
    }
  }
}
}
.users-list {
  position: relative;

  .link-button {
    opacity: 0;
  }

  th {
    font-weight: 500 !important;
  }

  tbody {

    tr:hover {
      position: relative;
      box-shadow: 0 6px 10px 0 rgba(38, 55, 72, 0.16);

      td {
        position: relative;
      }

      td:first-child:before {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: -10px;
        height: 100%;
        width: 10px;
        background-color: white;

        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;

        box-shadow: -3px 0px 2px 0 rgba(38, 55, 72, 0.06);
      }

      td:last-child:after {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        right: -10px;
        height: 100%;
        width: 10px;
        background-color: white;

        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;

        box-shadow: 3px 0px 2px 0 rgba(38, 55, 72, 0.06);
      }

      .link-button {
        opacity: 1;
      }
    }
  }
}


@media(max-width: 1024px) {
  .users-list {
    overflow-x: scroll;

    .link-button {
      opacity: 1 !important;
      min-width: 100px;
    }
    th {
      // width: auto;
    }
    td:before,
    td:after {
      display: none !important;
    }
    tr {
      box-shadow: none !important;
    }
  }
}
// .result-form {
//   &__params {
//     padding-bottom: 45px;
//     border-bottom: 2px solid #efeff4;
//     margin-bottom: 40px;
//   }

//   &__back-button {
//     margin-right: auto;
//   }

//   &__qr-code {
//     margin-right: 10px;
//   }

//   &__footer {
//     margin-top: 20px;
//     display: flex;
//   }

//   &__label {
//     margin-bottom: 10px;
//   }
// }

// @media (max-width: 720px) {
//   .result-form {
//     &__footer {
//       flex-direction: column;
//       align-items: center;
//     }

//     &__download {
//       align-items: center;
//       width: 100%;
//       display: flex;
//       flex-direction: column;
//     }

//     .button {
//       width: 100%;
//     }

//     &___back-button {
//       display: none;
//     }
//   }
// }

.result-form__qr-code {
  display: inline-block;
  padding: 20px;
  border-radius: 13px;
  box-shadow: 0 3px 9px 0 rgba(38, 55, 72, 0.1);
  background-color: #ffffff;

  margin-top: -30px;
  margin-bottom: 60px;
}

.result-form__params {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;

  border-radius: 10px;
  border: solid 1px #e8ecf1;

  padding: 30px;

  color: #3d4461;

  .params__field {
    overflow: hidden;
    line-height: 1.44;

    .params__key {
      color: #a1a7b7;
    }
  }
}

.resultPage {
  margin-bottom: 6px;
  padding: 0 50px;
  padding-bottom: 50px;
  border-radius: 5px;
  box-shadow: 0 3px 9px 0 rgba(38, 55, 72, 0.1);
  background-color: #ffffff;
  background-image: url('../../assets/svg/bg-verified.svg');
  background-position-y: -400px;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 1024px) {
  .resultPage {
    .result-form__params {
      grid-template-columns: 1fr;
    }
  }
}
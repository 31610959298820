.newtemplate {
  .edit-document {
    // grid-row-gap: 0;
    // overflow: hidden;
    
    h3 {
      margin-bottom: 20px;
    }
  }

  .base__button-add {
    padding: 0 20px;
  }

  .add-row-btn {
    display: block;
    width: 100%;
    padding: 19px 50px;
    height: auto;

    margin-top: 20px;

    font-size: 15px;
    color: #1ca3ff;
    border: solid 0.5px #80cbff;
    background-color: #eff9ff;

    box-shadow: none;

    &:hover {
      color: white;
    }
  }

  &__error {
    margin-bottom: 24px;
  }

  &__tables {
    margin-bottom: 20px;

    .newtemplate__metaData {
      margin-bottom: 0;
    }
    .newtemplate__metaData-buttons {
      align-items: center;
      margin-bottom: 0;
    }
  }

  &__metaTable {
    margin-bottom: 20px;

    &-inputs {
      margin-bottom: 20px;
    }

    .button {
      &-newtable {
        display: inline-block;
        width: auto;
        margin-bottom: 20px;
      }
    }
  }

  &__metaData {
    position: relative;

    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    // margin-bottom: 20px;

    &:hover {
      .newtemplate__metaData-buttons {
        opacity: 1;
      }

      .newtemplate__metaData-inputs {
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.07);
        border: solid 1px #f5f5f5;
      }
    }

    &-inputs {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 20px;

      @media(min-width: 1025px) {
        margin-left: -40px;
        margin-right: -40px;

        padding: 20px 40px;
      }



      border: solid 1px transparent;

      .input {
        width: 100%;
        margin-right: 20px;
        flex-shrink: 0;
      }

      .base__form-input {
        margin-bottom: 0;
      }

      .input:nth-child(2) {
        flex: 1;
      }
    }

    &-buttons {
      position: absolute;
      top: 30px;
      right: -30px;
      display: flex;

      opacity: 0;

      .button {
        padding: 0;
        height: 24px;
        width: 24px;

        font-size: 24px;

        background-color: #f13b4d;
        border: none;
        border-radius: 30px;

        box-shadow: none;

        span {
          margin-top: -4px;
        }
      }
    }
  }
}


@media(max-width: 1024px) {
  .newtemplate .edit-document {
    overflow: hidden;
    grid-row-gap: 0;
  }
  .base__form {
    background-color: transparent !important;
    // overflow-x: hidden;
  }
  .newtemplate__metaData-inputs {
    grid-template-columns: 1fr;

    border: none  !important;
    box-shadow: none !important;
  }
  .newtemplate__metaData-buttons {
    position: relative;
    opacity: 1;

    top: auto;
    right: auto;

    margin-top: 20px;
  }
  .newtemplate {
    .radio-list {
      margin: 20px 0;
    }

    .form-control + .form-control {
      margin-bottom: 20px;
    }
  }
}
.header {
  position: relative;
  display: flex;
  align-items: center;
  height: 86px;
  background-image: linear-gradient(92deg, #041865, #50a0ff);
  background: linear-gradient(92deg, rgba(4, 24, 101, 0.9), rgba(80, 160, 255, 1)),
    url(../../assets/svg/bg-verified.svg) no-repeat, linear-gradient(92deg, rgb(4, 24, 101), rgb(80, 160, 255));

  &__wrapper {
    width: 1180px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__nav {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__link {
    display: flex;
    align-items: center;
    margin-left: 30px;

    font-size: 16px;
    color: #ffffff;
    text-decoration: none;

    opacity: 0.8;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    span {
      font-size: 15px;
      margin-left: 10px;
    }
  }

  .navbar-wrapper {
    display: flex;
    align-items: center;
  }

  .header__searchField {
    position: relative;
    margin-left: 40px;
    color: #caccd4;

    input {
      width: 233px;
      height: 38px;

      padding-left: 40px;
      padding-right: 20px;

      border: none;
      border-radius: 20px;

      outline: none !important;
    }

    svg {
      position: absolute;
      left: 15px;
      top: 12px;
    }
  }
}

@media (max-width: 1024px) {
  .header {
    .menuClose {
      position: absolute;

      top: 30px;
      right: 20px;

      font-size: 20px;
    }

    &__wrapper {
      width: 100%;
      padding: 0 20px;
    }

    &__nav {
      display: none;

      &.active {
        position: absolute;
        display: block;

        width: 100%;
        height: 100vh;

        top: 0px;
        left: 0;

        background-image: linear-gradient(145deg, #000ecc, #4ea7ff);
        background: linear-gradient(92deg, rgba(4, 24, 101, 0.9), #50a0ff),
          url(/static/media/bg-verified.448b13e3.svg) no-repeat, linear-gradient(92deg, #041865, #50a0ff);

        padding: 20px;
        padding-top: 100px;

        color: white;

        z-index: 50;
      }
    }

    &__link {
      opacity: 1;
      margin: 20px;

      color: #52a1ff;

      &.ProfileLink {
        position: absolute;
        width: 100%;
        bottom: 10px;
        left: -20px;

        padding-top: 30px;
        padding-left: 40px;
        border-top: 1px solid rgba(255, 255, 255, 0.5);
      }

      i {
        width: 42px;
        height: 42px;
        background-color: white;
        border-radius: 50%;

        display: inline-flex;
        align-items: center;
        justify-content: center;
      }

      span {
        margin-left: 20px;

        font-size: 21px;
        color: white;
      }
    }

    &__backBtn {
      color: white;
    }

    &__menuBtn {
      color: white;
    }
  }
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background-color: #fcfdfe;
  font-family: "CircularStd", sans-serif;
}

.app-body {
  width: 1180px;
  margin: 0 auto;
}

.sidebar {
  display: none;
}

.base {
  padding-top: 30px;
  margin-bottom: 50px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-wrapper {
      display: flex;
      align-items: center;

      color: #50a0ff;

      svg {
        cursor: pointer;
      }
    }
  }

  &__title {
    color: #041865;
    font-size: 30px;
    font-weight: 500;
  }

  &__btns {
    display: flex;
    .button {
      margin-left: 10px;
    }
  }
}

.navbar-brand {
  display: inline-block;
  width: 146px;
  height: 39px;
  margin-left: 0;
  background-image: url(../assets/svg/logo.svg);
  background-size: contain;
  background-repeat: no-repeat;

  &-large {
    width: 438px;
    height: 117px;
    margin-bottom: -15px;
    margin-left: -15px;
  }
}

@media (min-width: 1025px) {
  body {
    min-width: 1280px;
  }
}

@media (max-width: 1024px) {
  .app-body {
    width: 100%;
    padding: 20px;

    // overflow: hidden;
  }

  .base {
    padding-top: 0;
    background-color: transparent;

    &__title {
      margin-top: 0;
    }

    &__header {
      flex-direction: column;
      margin-bottom: 20px;

      align-items: normal;

      &-wrapper {
        font-size: 0;

        svg {
          display: none;
        }
      }
    }

    &__btns {
      .button {
        margin-left: 0;
        margin-right: 10px;
      }
    }

    &__form {
      padding: 0 !important;
      box-shadow: none !important;

      color: #3d4461;

      p {
        font-size: 18px;
      }
    }
  }

  .navbar-brand {
    height: 30px;
  }
}

.profile {
  padding-top: 20px;
  &__title {
    font-size: 20px;
  }

  &__token {
    font-size: 15px;
    letter-spacing: 0.5px;
  }

  a {
    // color: darkblue !important;
    cursor: pointer;
    text-decoration: underline !important;
  }
}

@media (max-width: 1024px) {
  .profilePageBlock {
    .profile__title {
      margin-top: 0;
      font-size: 14px;
      font-weight: normal;
      opacity: 0.5;
    }

    .profile__token {
      font-size: 14px;
      opacity: 0.75;
    }

    b {
      color: #1ca3ff;
    }
  }
}

@media (min-width: 1025px) {
  .profilePageBlock {
    padding: 60px 160px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 6px 10px 0 rgba(38, 55, 72, 0.16);

    b {
      color: #1ca3ff;
    }

    .profile__title {
      margin-top: 0;
      font-size: 18px;
      font-weight: normal;
      opacity: 0.5;
    }

    .profile__token {
      font-size: 18px;
      opacity: 0.75;
    }

    &.Grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-row-gap: 20px;

      .profile {
        .profile__token {
          max-width: 150px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

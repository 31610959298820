.Select {
  // margin-bottom: 20px;
  padding: 0;

  &.is-focused {
    .Select-control {
      outline: 0;
      // box-shadow: 0 2px 12px 0 rgba(82, 161, 255, 0.2)!important;
      // border: solid 1px #caccd4;
      // border-color: #52a1ff!important;
    }
  }
}

.select_error {
  .Select-control {
    border-color: #ee5353!important;
  }
}

.Select-menu-outer {
  position: absolute;
  width: calc(100% + 2px);
  padding: 12px;
  padding-top: 20px;
  top: 35px;
  left: -1px;

  background-color: white;

  border-bottom: solid 1px #caccd4;
  border-left: solid 1px #caccd4;
  border-right: solid 1px #caccd4;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  .Select-option {
    padding: 5px 0;
    cursor: pointer;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
}

.Select-value {
  margin: 10px 0 !important;
  margin-right: 5px !important;
  vertical-align: middle!important;
  &:first-child {
    margin-left: 8px !important;
  }
}

.Select-multi-value-wrapper {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.Select-arrow-zone {
  padding-right: 25px!important;
  display: none;
}

.Select-placeholder {
  // line-height: 56px;
  // padding-left: 25px;
  display: none;
}

.Select-input {
  position: relative;
  // width: 100%;

  input {
    // width: 100%;
    font-size: 15px;
    border: none;
    outline: none !important;
    padding: 12px 1px;
  }

  input + div {
    // width: 100%;
    left: 0;
  }
  // height: 52px;
  // input {
  //   height: 37px;
  // }
}

#react-select-2--backspace-remove-message {
  display: none;
}
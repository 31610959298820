.form-control {
  position: relative;
}

.error__label {
  display: block;
  text-align: center;
  color: darkred;
}

.input {
  position: relative;
  padding: 12px 15px;

  border: solid 1px #caccd4;
  border-radius: 5px;
  background-color: transparent;

  font-size: 15px;
  color: #333;
  z-index: 1;

  &.input_error {
    border: solid 1px darkred;
  }

  &:focus,
  &:active {
    border: solid 1px #1ca3ff;
    box-shadow: 0 3px 6px 0 rgba(39, 126, 213, 0.16);
    outline: none !important;
    color: #3d4461;
  }

  &:disabled {
    color: grey!important;
  }

  & + label {
    display: block;
    position: absolute;
    transition: all 0.2s ease-in-out;
    top: 13px;
    left: 17px;
    color: silver;
    font-size: 15px;
    z-index: 0;
  }

  &:not(:placeholder-shown) + label,
  &:focus + label {
    top: -2px;
    font-size: 9px;
    color: #0033cc !important;
    background-color: white;
    padding: 0 5px;
    z-index: 2;
  }

  &:not(:placeholder-shown),
  &:active,
  &:focus {
    position: relative;
    background-color: transparent;
    border-color: #caccd4;
    box-shadow: none;
  }
}


.base__form {
  padding: 20px;
  background-color: white;
  box-shadow: 0 3px 9px 0 rgba(38, 55, 72, 0.1);
}

input[type="file"] {
  visibility: hidden;
  position: absolute;
  left: -50000;
}



.inputFile {
  position: relative;
  
  input {
    cursor: pointer;
    &::placeholder {
      color: #aeb1c1;
      font-size: 15px;
    }
  }

  span {
    position: absolute;
    top: 10px;
    right: 10px;

    color: #1ca3ff;
    font-size: 20px;
  }
}
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  height: 42px;
  padding: 10px 20px;

  box-shadow: 0 3px 6px 0 rgba(56, 149, 212, 0.21);
  background-color: #1ca3ff;
  border: solid 2px #1ca3ff;
  border-radius: 5px;

  color: #ffffff;
  font-size: 1em;
  letter-spacing: 0.08px;
  text-decoration: none;
  white-space: nowrap;

  transition: 0.3s ease;

  cursor: pointer;

  outline: none !important;

  &:disabled {
    &,
    &:hover,
    &:active,
    &:focus {
      background: #909cab;
      border-color: #909cab;
    }
  }

  &:hover {
    background: darken(#52a1ff, 8%);
    border-color: darken(#52a1ff, 8%);
    text-decoration: none;
  }
}

.link-button {
  display: inline-block;
  padding: 7px 12px;
  padding-top: 8px;

  font-size: 13px;
  color: #1ca3ff;
  line-height: 1.15;
  letter-spacing: 0.07px;
  text-decoration: none;

  border-radius: 3px;
  background-color: #ccebff;

  cursor: pointer;
}

.relative {
  position: relative;
}

.red {
  color: #f13b4d;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}


.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


.hideMe {
  visibility: hidden;
}

.hidden {
  display: none !important;
}

.hiddenMobile {
  @media (max-width: 1024px) {
    display: none !important;
  }
}

.hiddenDesktop {
  @media (min-width: 1025px) {
    display: none !important;
  }
}
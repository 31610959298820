.edituser {
  &__error {
    margin-bottom: 24px;
  }
}


.edit-client {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  margin: 100px 160px;

  .fullcell {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .input {
    width: 100%;
  }

  .radio-list {
    li {
      margin-right: 30px;
    }
  }
}
.base__form-internal-saech {
  .react-datepicker__close-icon {
    top: 1px;
    z-index: 1;
  }

  .multiple-inputs {
    display: flex;
    align-items: center;
    position: relative;

    .button {
      margin-left: 4px;
    }
  }

  .input-wrapper {
    position: relative;
  }

  .input {
    position: relative;
    width: 269px;
    margin: 0;
    border-right-width: 0 !important;
    border-radius: 0;
    background-color: transparent;
    z-index: 1;
    color: black;

    & + label {
      top: 13px;
    }

    &:not(:placeholder-shown) + label,
    &:focus + label {
      top: 2px;
      left: 12px;
    }

    &:not(:placeholder-shown),
    &:active,
    &:focus {
      padding: 14px 15px 10px 15px;
    }
  }

  .input-first {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  .input-last {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-right-width: 1px !important;
  }
}

.internal-search__result {
  padding: 50px 0;

  &-title {
    margin-bottom: 30px;

    font-size: 22px;
    font-weight: 500;
    color: #3d4461;
  }

  &-document {
    display: flex;
    align-items: center;
    // justify-content: space-between;

    height: 129px;
    margin-bottom: 6px;
    padding: 0 50px;

    border-radius: 5px;
    box-shadow: 0 3px 9px 0 rgba(38, 55, 72, 0.1);
    background-color: #ffffff;

    color: #3d4461;

    &:hover {
      margin-left: -6px;
      margin-right: -6px;
      padding: 0 56px;
      box-shadow: 0 12px 20px 0 rgba(38, 55, 72, 0.16);
    }

    .document-icon {
      padding-right: 40px;

      font-size: 44px;
      color: #caccd4;
    }

    .document-name {
      width: 280px;

      h3 {
        font-size: 21px;
        margin-top: -2px;
        margin-bottom: 10px;
      }

      i {
        color: #1ca3ff;
        font-style: normal;
      }
    }

    .document-dates {
      width: 480px;

      &-icon {
        color: #caccd4;
        margin-right: 10px;
      }

      &-date {
        display: inline-block;
        margin-right: 40px;
      }

      i {
        color: #0033cc;
        font-style: normal;
      }
    }

    .document-revoke {
      width: 130px;

      span {
        font-size: 13px;
      }

      .btn {
        width: 86px;
        display: inline-flex;
        align-items: center;

        padding: 7px 12px;
        border-radius: 3px;
        background-color: #fccfd4;
        color: #f13b4d;
        font-size: 13px;

        cursor: pointer;

        svg {
          margin-top: 2px;
        }
      }
    }

    .document-actions {
      a,
      span {
        display: block;
        padding: 7px 12px;
        margin-bottom: 6px;

        font-size: 13px;
        color: #1ca3ff;
        line-height: 1.15;
        letter-spacing: 0.07px;
        text-decoration: none;

        border-radius: 3px;
        background-color: #ccebff;

        cursor: pointer;

        svg {
          margin-right: 5px;
        }

        &:first-child {
          svg {
            margin-right: 7px;
          }
        }
      }
    }
  }
}

.PreviewModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(128, 142, 180, 0.78);

  display: flex;
  align-items: center;
  justify-content: center;
}

.PreviewModal {
  min-width: 540px;
  max-width: 50vw;
  margin: 0 auto;

  overflow: hidden;

  border-radius: 6px;
  background-color: #e3e7f0;
  box-shadow: 0 52px 32px 0 rgba(34, 39, 66, 0.1);

  outline: none;

  .ModalClose {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 32px;
    height: 32px;
    border-radius: 50%;

    color: white;
    background-color: #e1e2e7;

    cursor: pointer;

    &:hover {
      background-color: silver;
    }
  }

  h3 {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: 0;
    padding: 10px 25px;
    padding-right: 10px;

    font-size: 22px;
    color: #3d4461;
    background-color: white;
  }

  canvas {
    width: auto !important;
    height: 70vh !important;
  }

  .mgrpdf__wrapper > div {
    padding: 20px;
    padding-top: 0;

    div {
      max-width: 540px;
    }
  }

  .mgrpdf-navigation div {
    padding: 0px !important;
    padding-top: 20px !important;
  }
}

@media (max-width: 1024px) {
  .base__form-internal-saech {
    .multiple-inputs {
      flex-direction: column;
      align-items: normal;

      .form-control {
        margin-bottom: 20px;
      }

      .input {
        width: 100%;
        padding: 12px 15px;
        border: solid 1px #caccd4;
        border-radius: 3px;
        border-right-width: 1px !important;
        &.input_error {
          border: solid 1px red;
        }
      }

      .button {
        margin: 0;
      }

      .react-datepicker__input-container {
        display: block;
      }

      .input:not(:placeholder-shown) + label, .input:focus + label {
        top: -5px;
      }
    }
  }

  .internal-search__result {
    &-document {
      display: block;
      height: auto;
      padding: 20px !important;
      margin: 0 !important;
      margin-bottom: 20px !important;

      .document-icon {
        font-size: 18px;
        float: left;
        padding-right: 10px;
        padding-top: 1px;
      }
      .document-dates {
        display: flex;
        margin: 20px 0;

        i {
          display: block;
        }
      }

      .document-revoke {
        width: auto;
        margin: 20px 0;
        .btn {
          width: 100%;
        }
      }

    }
  }
}
